import React from 'react';

import SEO from '../../../blocks/SEO';
import Layout from '../../../blocks/Layout';
import IntegrationHeader from './IntegrationHeader';
import IntegrationContent from './IntegrationContent';

const Integration = ({ pathContext }) => {
  const { integration } = pathContext;

  return (
    <Layout>
      <SEO
        title={`Integrations - ${integration.title} | Deadline Funnel`}
        description={`Get started with Deadline Funnel and ${integration.title}`}
      />
      <IntegrationHeader integration={integration} />
      <IntegrationContent integration={integration} />
    </Layout>
  )
};

export default Integration;