import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './IntegrationContent.module.css';

import Text from '../../../../elements/Text';
import Container from '../../../../blocks/Container';
import InfoBlock from '../../../../blocks/InfoBlock';
import Image from '../../../../elements/Image';
import IntegrationSidebar from './IntegrationSidebar';
import HorizontalCollapse from './HorizontalCollapse';
import ScrollMagic from 'scrollmagic';

const IntegrationContent = ({ integration }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/integration-steps.md/" } }) {
        edges {
          node {
            frontmatter {
              steps {
                name
                number
                title
                infoBlock {
                  type
                  content
                }
                blocks {
                  type
                  value
                  maxWidth
                }
              }
            }
          }
        }
      }
    }
  `);

  const { steps } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const filteredSteps = steps.filter(step => integration.steps.some(integrationStep => integrationStep === step.name));

  const renderStepBlocks = (block, needBottomMargin) => {
    const { type, value, maxWidth } = block;

    if (type === 'image') return (
      <div key={value} className={styles.step__imageContainer}>
        <Image name={value} className={styles.step__image} style={{ maxWidth }} />
      </div>
    );

    return (
      <Text key={value} className={`${needBottomMargin ? 'mb-4' : ''}`}>
        <div dangerouslySetInnerHTML={{ __html: value.replace('{{integrationName}}', integration.title) }} />
      </Text>
    )
  };

  const setActiveLink = (condition, step) => {
    const linksList = document.getElementsByClassName(`link_to_${step.name}`);

    if (condition) {
      for (let element of linksList) {
        element.classList.add(styles.integrationContent__linkActive);
      }
      return;
    }

    for (let element of linksList) {
      element.classList.remove(styles.integrationContent__linkActive);
    }
  };

  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    const addScrollScene = (step, index, controller) => {
      new ScrollMagic.Scene({
        triggerElement: `#${step.name}`
      })
        .addTo(controller)
        .on('enter', () => {
          filteredSteps.filter(step => step.title).forEach((innerStep, innerIndex) => {
            setActiveLink(index === innerIndex, innerStep);
          });
        })
        .on('leave', () => {
          filteredSteps.filter(step => step.title).forEach((innerStep, innerIndex) => {
            setActiveLink(index - 1 === innerIndex, innerStep);
          });
        });
    };

    filteredSteps.filter(step => step.title).forEach((step, index) => addScrollScene(step, index, controller));

  }, [filteredSteps]);

  return (
    <div className={styles.integrationContent}>
      <Container>
        <Container type="mobile">
          <div className="row">
            <div className={`${styles.stepsContainer} col-xl-9 col-lg-9 col-12`}>
              <div className={styles.breadcrumbs}>
                <a href="/integrations">Integrations</a> / {integration.title}
              </div>
              {
                filteredSteps.map((integrationStep, index) => (
                  <div key={index} id={integrationStep.name} className={styles.step}>
                    {
                      integrationStep.title &&
                      <div className={`${styles.step__title} ${!integrationStep.number ? `${styles.step__titleWithoutNumber} mb-3` : 'mb-2'} `}>
                        {
                          integrationStep.number &&
                          <div className={styles.step__number}>
                            {index + 1 - filteredSteps.filter((step, stepIndex) => !step.number && stepIndex < index).length}
                          </div>
                        }
                        <span className={styles.step__text}>
                        {integrationStep.title.replace('{{integrationName}}', integration.title)}
                      </span>
                      </div>
                    }
                    {
                      integrationStep.blocks.map((block, blockIndex) => {
                        return renderStepBlocks(block, blockIndex !== (integrationStep.blocks.length - 1))
                      })
                    }
                    {
                      integrationStep.infoBlock &&
                      <InfoBlock type={integrationStep.infoBlock.type} className="mt-5">
                        <div dangerouslySetInnerHTML={{ __html: integrationStep.infoBlock.content.replace('{{integrationName}}', integration.title) }} />
                      </InfoBlock>
                    }
                  </div>
                ))
              }
            </div>
            <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">
              <Sticky
                top={70}
                bottomBoundary={`#${filteredSteps[filteredSteps.length - 1].name}`}
              >
                <IntegrationSidebar
                  integration={integration}
                  steps={filteredSteps.filter(step => step.title)}
                />
              </Sticky>
            </div>
            <HorizontalCollapse>
              <IntegrationSidebar
                integration={integration}
                steps={filteredSteps.filter(step => step.title)}
              />
            </HorizontalCollapse>
          </div>
        </Container>
      </Container>
    </div>
  )
};

IntegrationContent.proppTypes = {
  integration: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    popularity: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default IntegrationContent;