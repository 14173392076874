import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoBlock.module.css';

import { capitalize } from '../../../utils';

const InfoBlock = ({ type, children, className }) => {
  return (
    <div className={`${styles.infoBlock} ${styles[`infoBlock${capitalize(type)}`]} ${className}`}>
      <div className={styles.infoBlock__iconContainer}>
        <div className={styles.infoBlock__icon} />
      </div>
      <div className={styles.infoBlock__textContainer}>
        {children}
      </div>
    </div>
  )
};

InfoBlock.defaultProps = {
  className: ''
};

InfoBlock.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default InfoBlock;