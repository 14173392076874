import React from 'react';
import PropTypes from 'prop-types';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './IntegrationSidebar.module.css';
import Image from '../../../../../elements/Image';

const IntegrationSidebar = ({ integration, steps }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/integrations-list.md/" } }) {
        edges {
          node {
            frontmatter {
              integrationsList {
                title
                name
                image
              }
            }
          }
        }
      }
    }
  `);

  const { integrationsList } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const relatedIntegrations = integrationsList.filter(filteredIntegration => integration.related.includes(filteredIntegration.image));

  return (
    <div className={styles.integrationSidebar}>
      <div className="mb-4">Jump To</div>
      {
        steps.map(step => (
          <a
            key={step.name}
            href={`#${step.name}`}
            className={`${styles.integrationSidebar__link} link_to_${step.name}`}
          >
            {step.title.replace('{{integrationName}}', integration.title)}
          </a>
        ))
      }
      <div className={styles.integrationSidebar__relatedContainer}>
        <div className="mb-4">You Might Also Like...</div>
        {
          relatedIntegrations.map((relatedIntegration, index) => (
            <div key={index} className="d-flex justify-content-start align-items-center mb-3">
              <div className={styles.integrationSidebar__relatedImageContainer}>
                <Image name={relatedIntegration.image} className={styles.integrationSidebar__relatedImage} />
              </div>
              <a
                href={`/integrations/${relatedIntegration.name}`}
                className={`${styles.integrationSidebar__link} mb-0`}
              >
                {relatedIntegration.title}
              </a>
            </div>
          ))
        }
      </div>
    </div>
  )
};

IntegrationSidebar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  integration: PropTypes.object.isRequired
};

export default IntegrationSidebar;