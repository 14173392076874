import React from 'react';
import PropTypes from 'prop-types';

import styles from './IntegrationHeader.module.css';

import Logo from '../../../../../images/svg/logo_small.svg';
import Image from '../../../../elements/Image';
import Title from '../../../../elements/Title';
import Container from '../../../../blocks/Container';

const IntegrationHeader = ({ integration }) => {
  return (
    <div className={styles.integrationHeader}>
      <div className="d-flex">
        <Logo className={styles.integrationHeader__mainLogo} />
        <div className={styles.integrationHeader__integrationLogoContainer}>
          <Image
            name={integration.image}
            className={`
              ${styles.integrationHeader__integrationLogo} 
              ${integration.image === 'maropost' ? styles.integrationHeader__integrationLogoMaropost : ''}
            `}
          />
        </div>
      </div>
      <Container type="mobile">
        <Title type="small" className="mt-2">Deadline Funnel + {integration.title}</Title>
      </Container>
      <div className={styles.integrationHeader__text}>
        Get started with Deadline Funnel and {integration.title}!
      </div>
    </div>
  )
};

IntegrationHeader.proppTypes = {
  integration: PropTypes.shape({
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    popularity: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired
  }).isRequired
};

export default IntegrationHeader;