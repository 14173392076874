import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareLeft, faCaretSquareRight } from '@fortawesome/free-regular-svg-icons';

import styles from './HorizontalCollapse.module.css';

import { useOutsideClick } from '../../../../../../hooks/useOutsideClick';

const HorizontalCollapse = ({ children }) => {

  const [opened, setOpened] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setOpened, false);

  return (
    <div ref={wrapperRef} className={`${styles.horizontalCollapse} ${opened ? styles.horizontalCollapseOpened : ''}`}>
      <div
        className={styles.horizontalCollapse__control}
        onClick={() => setOpened(!opened)}
        onKeyDown={() => setOpened(!opened)}
        role="button"
        tabIndex={0}
      >
        <FontAwesomeIcon icon={opened ? faCaretSquareRight : faCaretSquareLeft} />
      </div>
      <div className={styles.horizontalCollapse__childrenContainer}>
        {children}
      </div>
    </div>
  )
};

HorizontalCollapse.propTypes = {
  children: PropTypes.node.isRequired
};

export default HorizontalCollapse;